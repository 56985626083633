.location {
  display: block;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid transparent;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 0px;
  background-color: white;
  box-sizing: border-box;
  max-width: 500px;
}

.location:hover {
  border: 1px solid gray;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
}

.location.active {
  border: 2px solid black;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
}

.location__name {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
  padding: 3px;
  background: rgba(39, 62, 98, 0.2);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.location__flag {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.location__type {
  align-self: center;
  font-size: 12px;
  font-weight: 600;
  background: #f5f5f5;
}

.location__milestones {
  align-self: flex-start;
  padding: 4px;
}

.milestone {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.milestone__qualifier {
  align-self: flex-start;
  font-size: 12px;
  line-height: normal;
  flex: 1;
}

.milestone__date {
  align-self: flex-end;
  font-size: 10px;
  line-height: normal;
  align-content: flex-end;
}

.location.active .milestone__date {
  display: none;
}

.milestone__details {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 100%;
  align-items: flex-end;
}

.milestone__color {
  border: 2px solid;
  border-radius: 2.5px;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  display: flex;
}

.milestone__color_status.milestone__arrived {
  background: rgb(39, 150, 39);
  border: 1px solid white;
  border-radius: 2px;
  flex: 1;
}

.milestone__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.identifiers {
  font-size: 10px;
}

.transport_name {
  font-weight: 600;
}

.tour_tree_wrapper {
  position: relative;
  background-color: rgb(239, 239, 239);
}

.tour_tree {
  display: grid;
  grid-auto-flow: dense;
  z-index: 2;
  position: relative;
}

.tour_tree_wrapper svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.tour_tree_wrapper svg line {
  stroke-width: 3px;
}

.tour_tree_wrapper svg text {
  font-size: 12px;
}

.tour_trips th {
  font-size: 12px;
}

.tour_trips td {
  font-size: 12px;
}

.tour_trips .color_swatch {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

svg circle {
  stroke: gray;
  stroke-width: 0.5px;
  fill: white;
}
