@import 'local_variables.css';

.error {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  overflow: auto;
}

body {
  -webkit-font-smoothing: antialiased;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.ui.menu,
.ui.menu .item,
.ui.large.header {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #273e62;
}

.ui.menu.submenu {
  flex-wrap: wrap;
}

b,
strong,
.ui.large.header {
  font-weight: 500;
}

.ui.list .list > .item .header,
.ui.list > .item .header,
.ui.list > .item .description {
  line-height: 1.5em;
  color: #273e62;
}

.Login {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}

.ui.secondary.menu .item.logo {
  padding-left: 0;
}

.ui.inline.loader.companies {
  margin-top: 50px;
}

#root {
  .notifications {
    height: 100%;

    .container {
      flex-shrink: 1;
      overflow: inherit;

      .content {
        overflow: inherit;

        > div {
          width: 100%;
        }

        > .loading {
          display: flex;
          justify-content: center;
          min-height: 100vh;
          align-items: center;
        }
      }
    }
  }
}

.workspace {
  flex-basis: 100%;
  padding: 15px 30px;
  box-sizing: border-box;
}

.map.pin.icon.stop_unloading {
  color: var(--color-blue-bright);
}

.map.pin.icon.stop_loading {
  color: var(--color-red);
}

.m8__horizontal {
  margin-bottom: 8px;
  margin-top: 8px;
}

.intercom_token {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.table__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > span {
    margin-right: 10px;
  }

  .ui.checkbox,
  .ui.radio {
    margin-bottom: 5px;
    margin-left: 10px;

    > label {
      cursor: pointer;
      user-select: none;
    }
  }

  .ui.dropdown,
  .ui.input {
    margin-right: 10px;
    font-size: 14px;

    &:first-of-type {
      margin-left: 10px;
    }
  }
}

.data-to-list {
  max-height: 150px;
  overflow: auto;
  position: relative;
  &:hover {
    > button.ui {
      display: block;
    }
  }

  > button.ui {
    position: absolute;
    top: 0;
    right: 4px;
    display: none;
  }
}

.link {
  text-decoration: none;
  color: #0046b8;

  &:hover {
    text-decoration: underline;
  }
}

.tour {
  height: calc(100vh - 100px);
  overflow: hidden;

  .tour__details {
    overflow: hidden;
    height: 100%;

    .ui.menu:after {
      content: none !important;
    }

    .tour__details__menu {
      justify-content: space-between;

      > * {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .tour__details__content {
    overflow-y: scroll;
    overflow-x: auto;
    padding-right: 5px;
    height: calc(100% - 85px);
    width: calc(100% + 10px);
  }
}

.tour__tour-list {
  overflow: auto;
}

.company {
  margin-bottom: 15px;

  .company__metadata-container {
    margin-bottom: 28px;

    .id_labels {
      max-width: 50%;
      .smallerRow {
        display: flex;
        justify-content: space-between;
        font-size: 7px;
        align-items: flex-end;
        padding-top: 5px;

        .country {
          border-radius: 5px;
          padding: 2px 5px;
          min-height: 10px;
        }

        .country:not(:empty) {
          background-color: white;
        }
      }
    }
  }
}

.company__featureFlags {
  &-header {
    display: flex;

    h2 {
      margin-right: 10px;
    }
  }

  &-container {
    .company__featureFlags-list {
      margin-right: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 13px;
    }
  }
}

.company__featureFlags-auditStackList {
  max-height: calc(100vh - 80px);
  overflow: auto;
}

.company__featureFlags-auditStackContainer {
  margin-top: 8px;
  margin-bottom: 8px;

  .company__featureFlags-auditStack {
    flex-grow: 1;
  }
}

.company__company-list {
  overflow: auto;
}

.vehicles__list__table {
  height: calc(100vh - 155px);
  overflow: auto;

  tr {
    td.vehicle_status_timestamp {
      border-right: 4px solid transparent;

      &.fresh {
        border-right-color: var(--color-green);
      }

      &.outdated {
        border-right-color: var(--color-yellow);
      }

      &.old {
        border-right-color: var(--color-red);
      }
    }
  }
}

.map-slider__container {
  padding: 5px;

  > .map-slider-tour {
    margin-bottom: 0;
  }
}

.map-slider {
  position: relative;
  width: 100%;
  padding-bottom: 10px;
}

.map-slider__header-debug {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 12px;

  .debug-btn {
    font-size: inherit;
    padding: 4px;
  }

  .debug-input-container {
    .debug-input-error {
      color: red;
    }
    input {
      border-radius: 3px;
      border: 1px solid var(--color-core-black-700);
      padding: 2px;
      min-width: 170px;
    }
  }
}

.map-slider__details {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 12px;

  > li:not(.wide-column) {
    width: 33%;

    &:last-of-type {
      text-align: right;
    }
  }
}

.map-slider-tour {
  > .map-slider {
    > .map-slider__ticks-container {
      height: 30px;
    }

    > .map-slider__input {
      height: 34px;
    }

    input[type='range']::-webkit-slider-thumb {
      height: 30px;
    }

    input[type='range']::-moz-range-thumb {
      height: 30px;
    }

    input[type='range']::-ms-thumb {
      height: 30px;
    }
  }
}

.map-slider-vehicle {
  > .map-slider {
    .map-slider-vehicle__input {
      &::-webkit-slider-thumb {
        background-color: currentColor;
      }
      &::-moz-range-thumb {
        background-color: currentColor;
      }
      &::-ms-thumb {
        background-color: currentColor;
      }
    }
  }
}

.map-slider__ticks-container {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  margin-left: 6px;
  margin-right: 4px;
  width: calc(100% - 10px);
  display: flex;
  height: 20px;
  background: var(--color-core-black-300);
}

.map-slider__tick-range {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  height: 4px;
  border-radius: 2px;

  &--stop {
    top: 0;
    background: var(--color-core-black-800);
  }

  &--timeslot-loading {
    top: 0;
    background-color: var(--color-timeslot-loading);
  }
  &--timeslot-unloading {
    top: 0;
    background-color: var(--color-timeslot-unloading);
  }

  &--events {
    bottom: 0;
    background-color: var(--color-core-yellow-400);
  }

  /* Colors matching the map_icons */
  /* Violet */
  &--small-break {
    bottom: 0;
    background-color: var(--color-small-break);
  }

  /* Pink */
  &--rest-break {
    bottom: 0;
    background-color: var(--color-rest-break);
  }

  /* Orange */
  &--we-break {
    bottom: 0;
    background-color: var(--color-weekend-break);
  }

  &--telemetry-chunk {
    top: 8px;
    background-color: var(--color-core-black-500);
  }
}

.map-slider__tick-event {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 1;
  color: var(--color-core-yellow-400);
  font-size: 10px;
  cursor: pointer;
  line-height: 2;
  text-shadow: 0 0px 1px #847e7e;
  transform: translateX(-50%);

  &--tractor-switch {
    color: var(--color-small-break);
  }
}

.map-slider__tick-external-event {
  position: absolute;
  height: 100%;
  top: 10px;
  z-index: 2;
  color: var(--color-core-black-300);
  font-size: 10px;
  cursor: pointer;
  line-height: 2;
  text-shadow: 0 0px 1px var(--color-core-blue-700);
  transform: translateX(-50%);

  &--mapped {
    color: var(--color-core-blue-700);
    opacity: 0.75;
  }
}

.map-slider__tick-tooltip {
  &:hover {
    z-index: 2;

    &:after {
      display: flex;
    }
  }

  &:after {
    content: attr(aria-label);
    display: none;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    bottom: 120%;
    right: 0;
    padding: 2px 4px;
    background: black;
    white-space: nowrap;
    color: white;
  }
}

.map-slider__input {
  height: 24px;
  width: 100%;
  position: absolute;
  top: -2px;
  bottom: 5px;
}

.stats__sparkline {
  width: 100px;
  height: 16px;
  margin-left: 20px;
  display: inline-block;
}

.stats__icon-placeholder {
  visibility: hidden;
}

.stats__icon-red {
  color: red;
}

.stats__icon-green {
  color: green;
}

.stats__value {
  display: flex;
  position: relative;
}

.stats__cell-header {
  display: flex;
  align-items: center;
}

.stats__report-section {
  padding: 2em !important;
}

.stats__report-section-header {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}

.stats__report-section-heading {
  margin: 0 0.5em 0 0 !important;
}

.stats__report-description {
  font-style: italic;
}

.helptext {
  font-style: italic;
  margin: 30px 0;
}

.tour__tour-details__stop-item {
  padding-top: 8px !important;
  padding-bottom: 8px !important;

  &__stop-header {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px !important;

    > *:not(:first-child) {
      margin-left: 4px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    &__address {
      margin-right: auto;
      flex-shrink: 1;
      flex-grow: 1;
    }
  }
}

.tour__tour-details__event-prop-list {
  padding-top: 0 !important;
}

.tour__map-controls {
  display: flex;
  padding: 4px;
  font-size: 11px;

  > b,
  .ui.label {
    margin-right: 5px;
  }
}

.tour__map-controls {
  .ui.checkbox label {
    cursor: pointer;
    font-size: 11px;
  }
  .ui.dropdown {
    width: 100%;
  }
  .ui.dropdown .menu {
    left: -10px;
  }
}

.tour__feature__flag {
  vertical-align: middle;
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: 1px solid var(--color-core-black-900);
}

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #273e61;
  height: 20px;
  width: 10px;
  background: transparent;
  cursor: pointer;
  margin: 0;
}

input[type='range']::-moz-range-thumb {
  border: 1px solid #273e61;
  height: 20px;
  width: 10px;
  background: transparent;
  cursor: pointer;
}

input[type='range']::-ms-thumb {
  border: 1px solid #273e61;
  height: 20px;
  width: 10px;
  background: transparent;
  cursor: pointer;
}

/* Vehicle details view */
.vehicle .row {
  height: calc(100vh - 100px);
}

.vehicle > .row > .column.vehicle__details {
  display: flex;
  flex-direction: column;
}

.vehicle > .row > .column.vehicle__details > .vehicle__details__temperature {
  margin-top: 1rem;
}

.vehicle .vehicle__details .statistics {
  flex-shrink: 0;
}

.vehicle .vehicle__stats_header {
  margin-top: 0;
}

.vehicle__breaks-list {
  overflow-y: auto;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
}

.vehicle__breaks-list > .item {
  .icon-break {
    &--small-break {
      color: var(--color-small-break);
    }

    /* Pink */
    &--rest-break {
      color: var(--color-rest-break);
    }

    /* Orange */
    &--we-break {
      color: var(--color-weekend-break);
    }
  }
}

.integration__configuration-footer {
  display: flex;
  justify-content: space-between;
}

.telematics__integration-list {
  overflow: auto;
  overflow-y: hidden;
}

.hoverable {
  text-decoration-style: dotted;
  text-decoration-color: gray;
  text-decoration-line: underline;
}

.span-link {
  text-decoration: none;
  color: #0046b8;

  &:hover {
    text-decoration: underline;
    color: #1e70bf;
    cursor: pointer;
  }
}

.active__allocation {
  font-weight: bold;
}

.sync-history {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.sync-history__result {
  padding: 1px;
  display: flex;
}
.sync-history__result-historyflag {
  width: 25px;
  height: 24px;
  text-align: center;
}

.sync-history__errorDescription {
  max-width: 400px;
  max-height: 200px;
  overflow: auto;
}

.sync-history__lastError {
  max-height: 150px;
  overflow: auto;
}

.bold-text {
  font-weight: bold;
}

.scope__header {
  display: flex;
}

.permission-tip {
  margin-left: 2px !important;
}

.company__integrations-left {
  flex: 1 0 50%;
  white-space: nowrap;
  padding: 10px;
  box-sizing: border-box;
}
.company__integrations-left-widthLimit {
  @media (width >= 1440px) {
    max-width: 650px;
  }
}
.company__integrations-right {
  flex: 1 0 50%;
  white-space: nowrap;
  padding: 10px;
  box-sizing: border-box;
}

.company__relationships-identifierContainer {
  width: 100%;
  max-width: 250px;
  overflow: auto;
  background-color: var(--color-core-gray-400);
  margin: var(--size-spacing-xsmall);
}

.sync-state-box {
  background-color: rgba(213, 224, 230, 0.188);
  display: flex;
  flex-direction: column;
  font-family: monospace;
  justify-content: space-between;
  max-width: 1500px;
  max-height: 500px;
  overflow: auto;
  padding: var(--size-spacing-small);
  word-break: break-word;
  white-space: pre-wrap;
}

.delete-sync-state-button {
  align-self: flex-end;
  position: sticky;
  bottom: 0;
}

.companyIdentifierLabel {
  cursor: pointer;
  &:hover {
    background-color: var(--color-core-gray-400);
  }
}

.api-usage-container__select {
  margin-right: 10px;
}

.api-usage-container__filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 40px;
  max-width: 1000px;
}
