.diff_modal__separator {
  font-size: 18px;
}

.diff_modal {
  max-height: 80vh !important;
}

.diff_modal__header {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.diff_modal__header__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  margin-right: 24px;
}
