:root {
  --color-blue: #273e61;
  --color-blue-bright: #00aaff;
  --color-red: #d0021b;
  --color-green: #00c361;
  --color-black: #000000;
  --color-yellow: #f4f776;
  --color-component-background: #fafbfe;
  --color-neutral-gray: #d5e0e6;
  --color-small-break: #7f5cff;
  --color-rest-break: #ff76b1;
  --color-weekend-break: #e28f33;
  --color-timeslot-loading: #fd818dbf;
  --color-timeslot-unloading: #7aa9e8bf;
}
