.bigquery__input__fields {
  margin: 0 0 1em !important;
}

.bigquery__input__field__from {
  padding-left: 0 !important;
}

.bigquery__input__field__to {
  padding-right: 0 !important;
}

.bigquery__input__query__bar {
  display: flex;
  justify-content: space-between;
}

.bigquery__message__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bigquery__message__header__checkbox {
  display: flex;
  gap: 8px;
  align-items: center;
}

.bigquery__message__cell {
  word-break: break-all;
}

.bigquery__data_area {
  margin-top: 1em;
}
